<template>
  <div>
    <label
      v-if="label?.length"
      for="google-autocomplete"
      class="inline-block"
      :class="{ [labelFont]: labelFont }"
      >{{ label }}</label
    >
    <GMapAutocomplete
      id="google-autocomplete"
      class="py-2.5 min-h-[42px] px-5 border rounded-x1 focus:outline-none w-full text-font-primary text-sm"
      :class="{
        'border !border-error': invalid,
        'border focus:border-accent-color': !invalid,
        [inputStyle]: inputStyle,
      }"
      :placeholder="placeholder ? placeholder : 'Enter Full Address'"
      :options="{
        componentRestrictions: { country: 'au' },
      }"
      :value="value"
      @place_changed="setPlace"
    />
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  invalid: {
    type: Boolean,
    default: false,
  },
  value: {
    type: String,
  },
  label: {
    type: String,
  },
  labelFont: {
    type: String,
  },
  inputStyle: {
    type: String,
  },
  placeholder: {
    type: String,
  },
});

const emit = defineEmits(["change"]);

function setPlace(place) {
  emit("change", place);
}
</script>
